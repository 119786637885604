export enum EPlaybookTrigger {
  LeadUnresponsive = 'lead_unresponsive',
  TourStarted = 'tour_started',
  IncomingSms = 'incoming-sms',
  IncomingEmail = 'incoming-email',
  IncomingFacebookMessage = 'incoming-facebook-message',
  LeadIngested = 'lead_ingested',
  Manual = 'manual',
}

export const playbookTriggers = Object.values(EPlaybookTrigger);

type IPlaybookTriggerBase<
  T extends EPlaybookTrigger = EPlaybookTrigger,
  TParams = never,
> = {
  type: T;
  run_limit_per_lead: number;
  params: TParams;
};

type ILeadUnresponsivePlaybookTriggerParams = {
  time_unit: 'days' | 'hours' | 'minutes';
  time_value: number;
};
type ILeadUnresponsivePlaybookTrigger = IPlaybookTriggerBase<
  EPlaybookTrigger.LeadUnresponsive,
  ILeadUnresponsivePlaybookTriggerParams
>;

type ITourStartedPlaybookTriggerParams = {
  time_offset_type: 'before' | 'after';
  time_offset_unit: 'days' | 'hours' | 'minutes';
  time_offset_value: number;
};
type ITourStartedPlaybookTrigger = IPlaybookTriggerBase<
  EPlaybookTrigger.TourStarted,
  ITourStartedPlaybookTriggerParams
>;

type IIncomingSmsPlaybookTrigger =
  IPlaybookTriggerBase<EPlaybookTrigger.IncomingSms>;

type IIncomingEmailPlaybookTrigger =
  IPlaybookTriggerBase<EPlaybookTrigger.IncomingEmail>;

type IIncomingFacebookMessagePlaybookTrigger =
  IPlaybookTriggerBase<EPlaybookTrigger.IncomingFacebookMessage>;

type ILeadIngestedPlaybookTrigger =
  IPlaybookTriggerBase<EPlaybookTrigger.LeadIngested>;

type IManualPlaybookTrigger = IPlaybookTriggerBase<EPlaybookTrigger.Manual>;

export type IPlaybookTrigger =
  | ILeadUnresponsivePlaybookTrigger
  | ITourStartedPlaybookTrigger
  | IIncomingSmsPlaybookTrigger
  | IIncomingEmailPlaybookTrigger
  | IIncomingFacebookMessagePlaybookTrigger
  | ILeadIngestedPlaybookTrigger
  | IManualPlaybookTrigger;

export enum EPlaybookStepTypeGroup {
  TriggerFilter = 'trigger-filter',
  Filter = 'filter',
  Action = 'action',
}

export const playbookStepTypeGroups = Object.values(EPlaybookStepTypeGroup);

export enum EPlaybookTriggerFilter {
  IsFirstMessageInThread = 'is-first-message-in-thread',
  SpecificProperty = 'specific-property',
  HasPropertyTourLink = 'has-property-tour-link',
}

export const playbookTriggerFilters = Object.values(EPlaybookTriggerFilter);

export enum EPlaybookFilter {
  IsLastMessageFromLandlord = 'is-last-message-from-landlord',
  LastMessageSent = 'last_message_sent_ago',
  NoMessageHistory = 'no-message-history',
  NoShowingsBooked = 'no-showings-booked',
  SpecificProperty = 'specific-property',
  HasTourLink = 'has-property-tour-link',
}

export const playbookFilters = Object.values(EPlaybookFilter);

export enum EPlaybookAction {
  SendMessage = 'send_message',
}

export const playbookActions = Object.values(EPlaybookAction);

type IPlaybookTriggerFilterBase<
  T extends EPlaybookTriggerFilter,
  TParams = never,
> = {
  type_group: EPlaybookStepTypeGroup.TriggerFilter;
  type: T;
  params: TParams;
};

type IPlaybookTriggerFilterIsFirstMessageInThread =
  IPlaybookTriggerFilterBase<EPlaybookTriggerFilter.IsFirstMessageInThread>;

type IPlaybookTriggerFilterHasTourLink =
  IPlaybookTriggerFilterBase<EPlaybookTriggerFilter.HasPropertyTourLink>;

type IPlaybookTriggerFilterSpecificPropertyParams = { property_id: number };
type IPlaybookTriggerFilterSpecificProperty = IPlaybookTriggerFilterBase<
  EPlaybookTriggerFilter.SpecificProperty,
  IPlaybookTriggerFilterSpecificPropertyParams
>;

type IPlaybookTriggerFilter =
  | IPlaybookTriggerFilterIsFirstMessageInThread
  | IPlaybookTriggerFilterHasTourLink
  | IPlaybookTriggerFilterSpecificProperty;

type IPlaybookFilterBase<T extends EPlaybookFilter, TParams = never> = {
  type_group: EPlaybookStepTypeGroup.Filter;
  type: T;
  params: TParams;
};

type IPlaybookFilterIsLastMessageFromLandlord =
  IPlaybookFilterBase<EPlaybookFilter.IsLastMessageFromLandlord>;

type IPlaybookFilterLastMessageSentParams = {
  time_unit: 'days' | 'hours' | 'minutes';
  time_value: number;
};
type IPlaybookFilterLastMessageSent = IPlaybookFilterBase<
  EPlaybookFilter.LastMessageSent,
  IPlaybookFilterLastMessageSentParams
>;

type IPlaybookFilterNoMessageHistory =
  IPlaybookFilterBase<EPlaybookFilter.NoMessageHistory>;

type IPlaybookFilterNoShowingsBookedParams = { property_id: number };
type IPlaybookFilterNoShowingsBooked = IPlaybookFilterBase<
  EPlaybookFilter.NoShowingsBooked,
  IPlaybookFilterNoShowingsBookedParams
>;

type IPlaybookFilterSpecificPropertyParams = { property_id: number };
type IPlaybookFilterSpecificProperty = IPlaybookFilterBase<
  EPlaybookFilter.SpecificProperty,
  IPlaybookFilterSpecificPropertyParams
>;

type IPlaybookFilterHasTourLink = IPlaybookFilterBase<
  EPlaybookFilter.HasTourLink
>;

type IPlaybookFilter =
  | IPlaybookFilterIsLastMessageFromLandlord
  | IPlaybookFilterLastMessageSent
  | IPlaybookFilterNoMessageHistory
  | IPlaybookFilterNoShowingsBooked
  | IPlaybookFilterSpecificProperty
  | IPlaybookFilterHasTourLink;

type IPlaybookActionBase<T extends EPlaybookAction, TParams = never> = {
  type_group: EPlaybookStepTypeGroup.Action;
  type: T;
  params: TParams;
};

type IPlaybookActionSendMessageParams = {
  channel: string;
  message: string;
};
type IPlaybookActionSendMessage = IPlaybookActionBase<
  EPlaybookAction.SendMessage,
  IPlaybookActionSendMessageParams
>;

type IPlaybookAction = IPlaybookActionSendMessage;

type IPlaybookStepBase<
  T = IPlaybookTriggerFilter | IPlaybookFilter | IPlaybookAction,
> = T & {
  children: IPlaybookNode[] | null;
};

export type IPlaybookTriggerFilterStep = IPlaybookStepBase<IPlaybookTriggerFilter>;
export type IPlaybookFilterStep = IPlaybookStepBase<IPlaybookFilter>;
export type IPlaybookActionStep = IPlaybookStepBase<IPlaybookAction>;

export type IPlaybookStep =
  | IPlaybookTriggerFilterStep
  | IPlaybookFilterStep
  | IPlaybookActionStep;

export type IPlaybookNode = {
  // condition: never;
  step: IPlaybookStep;
};

export interface IPlaybookV2 {
  description: string;
  first_step: IPlaybookStep;
  title: string;
  trigger: IPlaybookTrigger;
  uuid: string;
}
