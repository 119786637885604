import React, { useEffect } from 'react';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import Auth from 'src/services/api/auth/Auth';
import { useLoggedInUser } from 'src/services/api';
import { useAppState } from 'src/AppStateProvider';
import { useTheme } from 'src/theme/theme';

import 'public/styles/variables.css';
import 'public/styles/global.css';

interface Props {
    children: React.ReactNode
}

export default function ThemeProvider({ children }: Props) {
    const isLoggedIn = !!Auth.accessToken;
    const { data: loggedInUser } = useLoggedInUser(!isLoggedIn);

    const { isTawkChatOpened } = useAppState();

    const theme = useTheme(isTawkChatOpened);

    useEffect(() => {
        const appRoot = document.getElementById('root');
        if (!appRoot) {
            return;
        }

        if (isTawkChatOpened) {
            appRoot.style.position = 'static';

            // @ts-expect-error Tawk_API is a global variable
            // eslint-disable-next-line camelcase
            Tawk_API.maximize?.();

            if (loggedInUser) {
                // @ts-expect-error Tawk_API is a global variable
                // eslint-disable-next-line camelcase
                Tawk_API.setAttributes({
                    userId: loggedInUser.id,
                    email: loggedInUser.email,
                    name: loggedInUser.name,
                }, console.error);
            }

        } else {
            if (!appRoot) {
                return;
            }

            // @ts-expect-error Tawk_API is a global variable
            // eslint-disable-next-line camelcase
            Tawk_API.minimize?.();

            appRoot.style.position = 'relative';
        }
    }, [isTawkChatOpened]);

    return (
        <MuiThemeProvider theme={theme}>
            <CssBaseline />
            {children}
        </MuiThemeProvider>
    );
}