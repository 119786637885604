import React, { createContext, useState } from 'react';

type IAppState = {
    isHelpSidebarOpened: boolean;
    setHelpSidebarOpened: Action<boolean>;

    isTawkChatOpened: boolean;
    setTawkChatOpened: Action<boolean>;
};

// @ts-expect-error lazy init
export const AppStateContext = createContext<IAppState>();

export const useAppState = () => {
    const context = React.useContext(AppStateContext);

    if (!context) {
        throw new Error('useAppState must be used within an AppStateProvider');
    }
    return context;
};

type Props = {
    children: React.ReactNode;
};

export default function AppStateProvider({ children }: Props) {
    const [isHelpSidebarOpened, setHelpSidebarOpened] = useState(false);
    const [isTawkChatOpened, setTawkChatOpened] = useState(false);

    return (
        <AppStateContext.Provider
            value={{
                isHelpSidebarOpened,
                setHelpSidebarOpened,

                isTawkChatOpened,
                setTawkChatOpened,
            }}
        >
            {children}
        </AppStateContext.Provider>
    );
}

type Action<T> = React.Dispatch<React.SetStateAction<T>>;
